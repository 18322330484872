import { useLazyQuery } from "@apollo/client";
import classNames from "classnames";
import { useEffect, useState } from "react";
import Carousel from "~/components/Carousel";
import ProductItem from "~/components/Items/ProductItem";
import ProductItemSkeleton from "~/components/Skeletons/ProductItemSkeleton";
import { CmsBlockProductList } from "~/constants/enum";
import { GET_CATEGORY_BY_SLUG } from "~/data/categories";
import { QUERY_GET_COLLECTIONS_BLOCKS } from "~/data/collections";
import {
  GET_PRODUCTS_BY_CATEGORY_ID,
  QUERY_BESTSELLERS_ON_MENU,
  QUERY_POPULAR_ON_MENU,
  QUERY_TRENDING_ON_MENU,
} from "~/data/mega-menu/gql";
import { Product } from "~/services/product";
import { transformProductItems } from "~/utils/transform";
import Text from "../../Text";
import HorizontalProductList from "./HorizontalProductList";
import VerticalProductList from "./VerticalProductList";

type Props = {
  // variables?: {
  //   url_key: string;
  //   page_size?: number;
  //   current_page?: number;
  // };
  url_key: string;
  current_page: any;
  page_size: any;
  typeProduct: CmsBlockProductList;
  type: "horizontal" | "vertical" | "slider";
  title: "";
  chunk?: number;
};

const ProductListOnMenu = ({
  // variables,
  url_key,
  current_page,
  page_size,
  type = "vertical",
  chunk = 5,
  typeProduct,
  title,
}: Props) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [queryBestseller] = useLazyQuery(QUERY_BESTSELLERS_ON_MENU, {
    context: { fetchOptions: { method: "GET" } },
    onCompleted(data) {
      setProducts(transformProductItems(data?.bestSellerProducts?.items));
      setLoading(false);
    },
  });

  const [queryCollection] = useLazyQuery(QUERY_GET_COLLECTIONS_BLOCKS, {
    context: { fetchOptions: { method: "GET" } },
    onCompleted(data) {
      setProducts(
        transformProductItems(data?.productCollection?.[0]?.items)
      );
      setLoading(false);
    },
  });

  const [queryPopular] = useLazyQuery(QUERY_POPULAR_ON_MENU, {
    context: { fetchOptions: { method: "GET" } },
    onCompleted(data) {
      setProducts(transformProductItems(data?.popularProducts?.items));
      setLoading(false);
    },
  });

  const [querytrending] = useLazyQuery(QUERY_TRENDING_ON_MENU, {
    context: { fetchOptions: { method: "GET" } },
    onCompleted(data) {
      setProducts(transformProductItems(data?.trendingProducts?.items));
      setLoading(false);
    },
  });

  const [queryGetCategoryId] = useLazyQuery(GET_CATEGORY_BY_SLUG, {
    context: { fetchOptions: { method: "GET" } },
    onCompleted(res) {
      queryProductByCategory({
        variables: {
          currentPage: current_page || 10,
          pageSize: page_size || 1,
          filter: {
            category_id: {
              in: res?.categories?.items?.[0]?.id
                ? [res?.categories?.items?.[0]?.id || ""]
                : [],
            },
          },
        },
      })
    },
  });

  const [queryProductByCategory] = useLazyQuery(GET_PRODUCTS_BY_CATEGORY_ID, {
    context: { fetchOptions: { method: "GET" } },
    onCompleted(data) {
      setProducts(transformProductItems(data?.products?.items));
      setLoading(false);
    },
  });

  const fetchData = (typeProduct: any) => {
    setLoading(true);
    switch (typeProduct) {
      case CmsBlockProductList.BestSeller:
        return queryBestseller({
          variables: {
            currentPage: current_page || 10,
            pageSize: page_size || 1,
          }
        });
      case CmsBlockProductList.Poppular:
        return queryPopular({
          variables: {
            currentPage: current_page || 10,
            pageSize: page_size || 1,
          }
        });
      // case CmsBlockProductList.Trending:
      //   return querytrending({
      //     variables: {
      //       currentPage: current_page || 10,
      //       pageSize: page_size || 1,
      //     },
      //     onCompleted(data) {
      //       setProducts(transformProductItems(data?.trendingProducts?.items));
      //       setLoading(false);
      //     },
      //   });
      case CmsBlockProductList.Category:
        return queryGetCategoryId({
          variables: {
            param: url_key,
          },
        })
      default:
        return queryCollection({
          variables: {
            url_key,
            page_size,
            current_page,
          }
        });
    }
  };
  useEffect(() => {
    fetchData(typeProduct);
  }, [typeProduct]);

  //  if(!products?.length && !loading) return null

  if (type === "horizontal") {
    return (
      <>
        <Text className="whitespace-nowrap" type="title-4">
          {title}
        </Text>
        <HorizontalProductList
          data={products}
          chunk={chunk}
          loading={loading}
        />
      </>
    );
  }
  if (type === "slider") {
    return (
      <>
        <Text className="whitespace-nowrap" type="title-2">
          {title}
        </Text>
        <Carousel
          slidesToScroll={chunk / 2}
          slidesToShow={chunk}
          rows={1}
          infinite={products?.length > 1}
          responsive={[
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
          ]}
        >
          {!!loading &&
            Array.from({ length: chunk }).map((_, index) => (
              <div
                key={index}
                className={classNames("px-[0.625rem] lg:px-[1.25rem]", {
                  "!py-[10px]": chunk > 1,
                })}
              >
                <ProductItemSkeleton />
              </div>
            ))}
          {!loading &&
            products.map((data: any, index: number) => (
              <div
                key={index}
                className={classNames("px-[0.625rem] lg:px-[1.25rem]", {
                  "!py-[10px]": chunk > 1,
                })}
              >
                <ProductItem
                  data={data}
                  size={chunk < 4 ? "large" : chunk < 5 ? "medium" : "small"}
                />
              </div>
            ))}
        </Carousel>
      </>
    );
  }
  return (
    <>
      <Text className="whitespace-nowrap" type="title-4">
        {title}
      </Text>
      {<VerticalProductList data={products} loading={loading} />}
    </>
  );
};

export default ProductListOnMenu;
