import { gql } from '@apollo/client';

// ------Collections------
export const QUERY_GET_AGGREGATIONS_COLLECTIONS = gql`
    query productCollection($url_key: String!, $page_size: Int = 35, $current_page: Int = 1) {
        productCollection(url_key: $url_key, page_size: $page_size, current_page: $current_page) {
            aggregations {
                count
                label
                attribute_code
                options {
                    count
                    label
                    value
                }
            }
            sort_fields {
                default
                options {
                    label
                    value
                }
            }
        }
    }
`;

export const QUERY_GET_COLLECTIONS = gql`
    query productCollection(
        $url_key: String!
        $page_size: Int = 30
        $current_page: Int = 1
        $filter: ProductAttributeFilterInput
        $sort: ProductAttributeSortInput
    ) {
        productCollection(
            url_key: $url_key
            page_size: $page_size
            current_page: $current_page
            filter: $filter
            sort: $sort
        ) {
            total_count
            current_page
            name
            campaign_id
            description
            meta_description
            meta_title
            url_key
            items {
                id
                name
                sku
                thumbnail {
                    url
                }
                url_key
            }
            aggregations {
                attribute_code
                options {
                    value
                    label
                    count
                }
            }
            sort_fields {
                default
                options {
                    label
                    value
                }
            }
        }
    }
`;
export const QUERY_GET_COLLECTIONS_BLOCKS = gql`
    query productCollection($url_key: String!, $page_size: Int = 24, $current_page: Int = 1) {
        productCollection(url_key: $url_key, page_size: $page_size, current_page: $current_page) {
            total_count
            current_page
            name
            description
            meta_description
            meta_title
            url_key
            aggregations {
                attribute_code
                options {
                    value
                    label
                    count
                }
            }
            items {
                id
                name
                sku
                categories {
                    id
                    url_key
                    name
                    level
                }
                thumbnail {
                    url
                }
                media_gallery {
                    url
                }
                categories {
                    id
                }
                url_key
                price_range {
                    minimum_price {
                        regular_price {
                            value
                            currency
                        }
                        final_price {
                            value
                            currency
                        }
                        discount {
                            amount_off
                            percent_off
                        }
                    }
                }
            }
        }
    }
`;

export const GET_PRODUCT_COLLECTIONS = gql`
    query ProductCollectionList {
        ProductCollectionList {
            campaign_id
            name
            meta_title
            meta_description
            description
            meta_keywords
            url_key
        }
    }
`;
