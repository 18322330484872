import { FC } from "react";
import { CmsBlockProductList, MegaMenuType, PATH } from "~/constants/enum";
import { parseVarFormCmsBlock } from "~/utils/converters";
import Text from "../Text";
import styles from "./MenuDropdown.module.scss";
import ProductListOnMenu from "./components/ProductListOnMenu";
import classNames from "classnames";
interface MenuDropdownProps {
  data: any;
}

const MenuDropdown: FC<MenuDropdownProps> = ({ data }) => {
  const RenderMenuText = ({ data }: any) => {
    const href =
      data?.type === MegaMenuType.Category
        ? PATH.CategoryDetail.replace("[slug]", data?.url)
        : !!data?.url
        ? data?.url
        : "#";
    return (
      <div className={styles.menu_item}>
        <a href={href} className={classNames("flex gap-2 relative px-4 py-2")}>
          {data.title}
          {!!data.badge && (
            <div className="flex gap-[2px] ml-3">
              <span
                className={styles.badge}
                style={{
                  color: data.badge?.color,
                  backgroundColor: data.badge?.background,
                }}
              >
                {data.badge?.title}
              </span>
            </div>
          )}
        </a>
        {data?.children?.length > 0 && (
          <div className={styles.menu_item_child}>
            {data?.children?.map((child: any) => {
              return <RenderMenuText key={child.id} data={child} />;
            })}
          </div>
        )}
      </div>
    );
  };

  const renderSpecialCmsBlock = (title: string, content: string) => {
    const params = parseVarFormCmsBlock(content);

    switch (params?.typeProduct) {
      case CmsBlockProductList.BestSeller:
      case CmsBlockProductList.Poppular:
      case CmsBlockProductList.Category:
      case CmsBlockProductList.Trending:
      case CmsBlockProductList.Collection:
        return (
          <ProductListOnMenu
            url_key={params?.urlKey}
            current_page={params?.currentPage}
            page_size={params?.pageSize}
            type={params?.type}
            typeProduct={params?.typeProduct}
            chunk={params?.chunk}
            title={params?.title}
          />
        );
      default:
        return (
          <>
            <Text type="title-4">{title}</Text>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </>
        );
    }
  };

  const rederMenuItem = (data: any) => {
    switch (data?.type) {
      case MegaMenuType.Wrapper:
        return (
          <div className="flex justify-between gap-6">
            {data?.children.map((sub: any, index: number) => (
              <div
                key={`${sub.id}-${index}`}
                className="flex flex-col gap-4 flex-1"
              >
                {rederMenuItem(sub)}
              </div>
            ))}
          </div>
        );
      case MegaMenuType.Category:
      case MegaMenuType.CustomUrl:
      case MegaMenuType.CmsPage:
        return <RenderMenuText data={data} />;
      // case MegaMenuType.Product:
      //     return <SignleProduct badges={data?.badges || []} title={data?.title} url={data?.url} />
      case MegaMenuType.CmsBlock:
        return renderSpecialCmsBlock(data.title, data.content);
      default:
        return null;
    }
  };
  return <>{rederMenuItem(data)}</>;
};

export default MenuDropdown;
