import SkeletonByTimeout from '~/components/Skeletons/SkeletonByTimeout'
import { Product } from '~/services/product'
import SimpleProduct from './SimpleProduct'
import { chunk as _chunk } from "lodash";
import { useMemo } from 'react';

type Props = {
  data: Product[]
  chunk?: number
  loading?: boolean
}

const HorizontalProductList = ({ loading, data = [], chunk = 5 }: Props) => {

  const products = useMemo(() => {
    if (loading) return Array.from({ length: 10 }, (ske: any, index) => ({} as Product))
    return data || []
  }, [data, loading])

  return (
    <div className="flex gap-6 w-full">
      {_chunk(products, chunk || 5).map(
        (chunk: any, index: number) => {
          return (
            <div key={index} className="flex flex-col gap-4 flex-1">
              {chunk.map((item: Product, index: number) => <SimpleProduct key={index} item={item} loading={loading} />)}
            </div>
          );
        }
      )}
    </div>
  )
}

export default HorizontalProductList