import React, { FC, LegacyRef, forwardRef } from "react";
import Slider, { Settings } from "react-slick";
import Icon from "../Icon";
export const SliderArrow: FC<any> = ({
  currentSlide,
  slideCount,
  children,
  ...props
}) => <div {...props}>{children}</div>;

interface CarouselProps extends Settings { }

const Carousel: FC<CarouselProps> = forwardRef(
  ({ children, ...props }: CarouselProps, ref?: LegacyRef<Slider>) => {
    const childArray = React.Children.toArray(children);
    const nonNullChildren = childArray.filter((child) => child !== null);
    const childCount = nonNullChildren.length;

    const isMobile = typeof window !== "undefined" && window.innerWidth < 1024;
    const isShowArrow = childCount > (props.slidesToShow || 1);
    const slidesToShow = props.slidesToShow || 1;

    let amountAddChild = slidesToShow - childCount;

    const settings = {
      speed: 500,
      // autoplaySpeed: 5000,
      // cssEase: "linear"
      swipeToSlide: true
      // pauseOnHover: true,
    };

    const _responsive = props.responsive?.map((item: any) => {
      const _isShowArrow = childCount > (item.slidesToShow || 1);

      if (
        (typeof window !== "undefined" && window.innerWidth) <= item.breakpoint
      ) {
        const slidesToShow = item?.settings?.slidesToShow || 1;

        amountAddChild =
          slidesToShow - childCount > 0 ? slidesToShow - childCount : 0;
      }
      return {
        ...item,
        settings: {
          ...item.settings,
          arrows: !isMobile && !!_isShowArrow,
          touchMove: !!isMobile && !!_isShowArrow,
        },
      };
    });

    return (
      //@ts-ignore
      <Slider
        {...settings}
        {...props}
        arrows={!isMobile && !!isShowArrow}
        touchMove={!!isMobile && !!isShowArrow}
        responsive={_responsive}
        // speed={1000}
        ref={ref}
        nextArrow={
          <SliderArrow>
            <Icon name="arrow-right-2" />
          </SliderArrow>
        }
        prevArrow={
          <SliderArrow>
            <Icon name="arrow-square-left-2" />
          </SliderArrow>
        }
      >
        {children}
        {amountAddChild > 0
          ? Array.from({ length: Math.ceil(amountAddChild) }).map(
            (_, index) => (
              <div key={index} className="slider__item">
                <div className="slider__item__inner"></div>
              </div>
            )
          )
          : null}
      </Slider>
    );
  }
);

Carousel.displayName = "Carousel";

export default Carousel;
