import { gql } from "@apollo/client";

export const GET_CATEGORIES = gql`
  query categories {
    categories {
      name
      total_count
      items {
        id
        level
        name
        breadcrumbs {
          category_name
          category_url_key
        }
        url_key
        children_count
      }
    }
  }
`;

export const GET_CATEGORIES_V2 = gql`
  query categories {
    categories {
      total_count
      page_info {
        page_size
        current_page
      }
      items {
        id
        level
        name
        description
        meta_title
        meta_description
        meta_keywords
        breadcrumbs {
          category_name
          category_url_key
        }
        url_key
        children_count
        children {
          id
          level
          name
          url_key
          children_count
          image
          children {
            id
            level
            name
            url_key
            image
            children {
              id
              level
              name
              url_key
              image
            }
          }
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
    }
  }
`;

export const GET_CATEGORY_BY_SLUG = gql`
  query categories($param: String!) {
    categories(filters: { url_key: { eq: $param } }) {
      total_count
      items {
        id
        level
        name
        breadcrumbs {
          category_name
          category_url_key
        }
        url_key
        children_count
      }
    }
  }
`;
export const GET_AGGREGATIONS = gql`
  query GetAggregations($filter: ProductAttributeFilterInput) {
    products(filter: $filter) {
      aggregations {
        count
        label
        attribute_code
        options {
          count
          label
          value
        }
      }
      sort_fields {
        default
        options {
          label
          value
        }
      }
    }
  }
`;

export const QUERY_OPTIMISTIC_PRODUCTS_BY_CATEGORY_ID = gql`
  query products(
    $pageSize: Int = 24
    $currentPage: Int = 24
    $filter: ProductAttributeFilterInput
  ) {
    products(filter: $filter, pageSize: $pageSize, currentPage: $currentPage) {
      items {
        name
        sku
        id
        url_key
        small_image {
          url
          label
        }
      }
      total_count
      page_info {
        page_size
        current_page
      }
    }
  }
`;
export const GET_META_CATEGORY_BY_SLUG = gql`
  query categories($id: String!) {
    categories(filters: { ids: { eq: $id } }) {
      items {
        id
        name
        meta_title
        meta_description
        meta_keywords
        breadcrumbs {
          category_name
          category_url_key
        }
        url_key
        url_path
        children_count
      }
    }
  }
`;

export const GET_PRODUCTS_BY_CATEGORY_RESOLVER = gql`
  query urlResolver($url: String!) {
    urlResolver(url: $url) {
      canonical_url
      entity_uid
      id
      redirectCode
      relative_url
      type
    }
  }
`;
export const GET_PRODUCTS_BY_CATEGORY_ID_FOR_RICH_CONTENT = gql`
  query products($category_id: String) {
    products(filter: { category_id: { in: [$category_id] } }) {
      items {
        url_key
      }
    }
  }
`;

export const QUERY_REVIEW_BY_CATEGORY_SHORT = gql`
  query reviewCategory($url_key: String!, $current_page: Int) {
    reviewCategory(url_key: $url_key, pageSize: 5, currentPage: $current_page) {
      page_info {
        page_size
        current_page
        total_pages
      }
      items {
        images
        summary
        text
        nickname
        created_at
        average_rating
        __typename
      }
      summary
      summary_detail
      __typename
    }
    __typename
  }
`;
