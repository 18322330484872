import classNames from "classnames";
import Skeleton from "react-loading-skeleton";

import SkeletonByTimeout from "~/components/Skeletons/SkeletonByTimeout";
import SkeletonContent from "~/components/Skeletons/skeleteContent";
import Text, { TextType } from "~/components/Text";
import Colors from "~/constants/colors";
import { PATH } from "~/constants/enum";
import { Product } from "~/services/product";
import { formatNumber } from "~/utils/number";
import { getConfig, getUrlCustom } from "~/utils/theme";
import styles from "./SimpleProduct.module.scss";
import { ProductItemSize } from "~/components/Items/ProductItem/ProductItem";

type Props = {
  item: Partial<Product>;
  loading?: boolean;
};

const PriceSizeMap: Record<ProductItemSize, TextType> = {
  large: "title-1",
  medium: "title-2",
  small: "title-4",
  smaller: "title-4",
  xsmall: "title-5", // Add this line to fix the error
};

const DiscountPriceSizeMap: Record<ProductItemSize, TextType> = {
  large: "body-2",
  medium: "body-3",
  small: "body-2",
  smaller: "body-2",
  xsmall: "body-3", // Added missing property
};

const ProductPrice = ({
  data,
  size = "medium",
}: {
  data: Partial<Product>;
  size: ProductItemSize;
}) => {
  return (
    <div className="flex flex-1 flex-wrap items-end gap-1">
      <Text
        className={classNames("text-primary", styles.price)}
        type={PriceSizeMap[size]}
      >
        {data.price_range?.minimum_price?.final_price?.value && (
          <>
            {data.price_range?.minimum_price?.final_price?.symbol || "$"}
            {formatNumber(
              data.price_range?.minimum_price?.final_price?.value,
              2
            )}
            +
          </>
        )}
      </Text>
      {!!data.price_range?.minimum_price?.discount?.percent_off && (
        <Text
          className="line-through text-grey-400"
          type={DiscountPriceSizeMap[size]}
        >
          $
          {formatNumber(
            data.price_range?.minimum_price?.regular_price?.value,
            2
          )}
        </Text>
      )}
      {!!data.price_range?.minimum_price?.discount?.percent_off && (
        <div
          style={{
            background: Colors.PRIMARY,
          }}
          className="text-white px-1"
        >
          <Text>{`${data.price_range?.minimum_price?.discount?.percent_off}% off`}</Text>
        </div>
      )}
    </div>
  );
};

const SimpleProduct = ({ item, loading }: Props) => {
  const themeConfig = getConfig("ProductItem");

  if (!item) return null;
  const size = "small";

  return (
    <a
      key={item?.sku || "#"}
      href={getUrlCustom({
        pathname: PATH.ProductDetail,
        query: {
          slug: item?.slug,
          variant: item.variants?.[0]?.product?.id,
        },
      })}
    >
      <div className="flex gap-3 items-start">
        <div style={{ width: 80, height: 80 }}>
          <SkeletonContent
            skeletonProps={{
              className: "aspect-square",
              containerClassName: "w-full h-full",
            }}
            condition={!!(loading && !item?.image?.url)}
          >
            <img
              className="shrink-0 w-full h-full"
              src={item.image?.url}
              alt={item.name}
            />
          </SkeletonContent>
        </div>
        <div className="flex flex-col gap-2 flex-1">
          <SkeletonContent
            condition={!!(!item?.name && loading)}
            skeletonNode={
              <>
                <Skeleton containerClassName="w-full" key={1}></Skeleton>
                <Skeleton containerClassName="w-[80%]" key={2}></Skeleton>
              </>
            }
          >
            <Text className="line-clamp-2" type="label-4">
              {item?.name}
            </Text>
          </SkeletonContent>
          <div
            className={classNames(
              "flex flex-1 flex-wrap justify-between gap-3",
              {
                "flex-col": themeConfig.starBreakSize.includes(size),
                "items-center": !themeConfig.starBreakSize.includes(size),
              }
            )}
          >
            <SkeletonContent
              condition={
                !!(!item?.price_range?.minimum_price?.final_price && loading)
              }
              skeletonNode={
                <div className="flex justify-between w-full">
                  <Skeleton containerClassName="flex-1" width="50%" />
                </div>
              }
            >
              <ProductPrice data={item} size={size} />
            </SkeletonContent>
          </div>
        </div>
      </div>
    </a>
  );
};

export default SimpleProduct;
