import React, { useMemo } from "react";
import { Product } from "~/services/product";
import SimpleProduct from "./SimpleProduct";

type Props = {
  data: Product[];
  loading?: boolean;
};

const VerticalProductList = ({ data = [], loading }: Props) => {
  const products = useMemo(() => {
    if (loading)
      return Array.from({ length: 5 }, (ske: any, index) => ({} as Product));
    return data || [];
  }, [data, loading]);

  return (
    <div className="flex flex-col gap-8">
      {products?.map((item: Product, index: number) => (
        <React.Fragment key={item.id ?? index}>
          {" "}
          <SimpleProduct item={item} loading={loading} />
        </React.Fragment>
      ))}
    </div>
  );
};

export default VerticalProductList;
