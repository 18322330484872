import classNames from "classnames";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { FC, Fragment } from "react";
import { v4 } from "uuid";
import Divider from "~/components/Divider";
import MenuDropdown from "~/components/Menu/MenuDropdown";
import Text from "~/components/Text";
import { MegaMenuType, PATH } from "~/constants/enum";
import styles from "./MenuItem.module.scss";

interface MenuItemProps {
  data: any;
  index?: number;
}
const MenuItem: FC<MenuItemProps> = ({ data }) => {
  const router = useRouter();
  const handleClickHref = () => {
    if (!data.url || data.url === "#") return;

    if (
      [
        MegaMenuType.CustomUrl,
        MegaMenuType.Category,
        MegaMenuType.CmsPage,
      ].includes(data?.type)
    ) {
      return router.push(data.url);
    }
    if (MegaMenuType.Product === data?.type) {
      return router.push({
        pathname: PATH.ProductDetail,
        query: {
          slug: data.url,
        },
      });
    }
    return;
  };

  const wrapperType = data?.type === MegaMenuType.Wrapper;
  return (
    <div className={classNames({ ["relative"]: !wrapperType })}>
      <div className={classNames(styles.menu_item_wrapper)}>
        <div
          onClick={handleClickHref}
          className={classNames(styles.menu_item, "menu-item")}
        >
          <Text htmlTag="span" type="label-3" weight={500}>
            {data.title?.toLowerCase()}
          </Text>
          {!!data.badge && (
            <div className="flex gap-[2px] ml-3">
              <span
                className={styles.badge}
                style={{
                  color: data.badge?.color,
                  backgroundColor: data.badge?.background,
                }}
              >
                {data.badge?.title}
              </span>
            </div>
          )}
        </div>
        {!isEmpty(data?.submenu) && data?.submenu && (
          <div
            className={classNames(styles.menu_dropdown, "dropdown-animation", {
              "dropdown-animation-show": true,
              [styles.fullWidth]: wrapperType,
              ["w-[300px]"]: !wrapperType,
            })}
            style={{
              left: 0,
              top: !wrapperType ? "calc(100% + 0.5rem)" : "100%",
            }}
          >
            <div className="flex flex-col gap-2 text-left">
              {data.submenu.map((submenuItem: any, index: number) => {
                return (
                  <Fragment key={submenuItem.id || v4()}>
                    <MenuDropdown data={submenuItem} />
                    {index < data.submenu.length - 1 && <Divider key={index} />}
                  </Fragment>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MenuItem;
