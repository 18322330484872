import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import Divider from "../Divider";

const ProductItemSkeleton: FC = () => {
  return (
    <div className="flex flex-col">
      <Skeleton className="aspect-square" />
      <div>
        <Skeleton />
        <Skeleton width="70%" />
      </div>
      {/* <Divider /> */}
      {/* <div className="flex justify-between w-full">
        <Skeleton containerClassName="flex-1" width="50%" />
        <Skeleton containerClassName="flex-1 text-right" width="50%" />
      </div> */}
    </div>
  );
};

export default ProductItemSkeleton;
