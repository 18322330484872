import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Skeleton, { SkeletonProps } from 'react-loading-skeleton';
import SkeletonByTimeout, { SkeletonByTimeoutProps } from './SkeletonByTimeout';

type Props = {
    timeOut?: number;
    children?: JSX.Element | JSX.Element[];
    onOverTimeLoading?: () => void;
    renderAfterLoading?: JSX.Element | JSX.Element[];
    condition: boolean;
    skeletonProps?: SkeletonProps;
    skeletonByTimeoutProps?: SkeletonByTimeoutProps;
    skeletonNode?: JSX.Element | JSX.Element[] | undefined;
};

const SkeletonContent = ({ children, skeletonProps, condition, skeletonByTimeoutProps, skeletonNode }: Props) => {
    if (condition) {
        return (
            <SkeletonByTimeout {...skeletonByTimeoutProps}>
                {!!skeletonNode ? skeletonNode : <Skeleton {...skeletonProps} />}
            </SkeletonByTimeout>
        );
    }

    return children;
};

export default SkeletonContent;
